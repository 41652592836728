/*******************************
 *     TEST Config
 ******************************/

export const canvasConfig = {
  redirectUrl: "https://test.blendedcourse.com/account/integrations",
  redirectToSyllabus: "https://test.blendedcourse.com/loaderRedirect",
};

export const teamsConfig = {
  redirectUrl: "https://test.blendedcourse.com/account/integrations",
  redirectToSyllabus: "https://test.blendedcourse.com/loaderRedirect",
  clientId: "2feba7b6-d6a6-425f-96c9-f4ced855ff53",
};

export const teamsConfigPrivate = {
  ...teamsConfig,
  ...{
    clientSecret: "hOR7Q~-pOgDI_2UYWfA_.INwDfh_DASTpGe-y",
  },
};

export const blackboardConfig = {
  redirectToSyllabus: "https://test.blendedcourse.com/loaderRedirect",
};

export const stripeConfig = {};

export const mixpanelConfig = {
  token: "409ad825eecaaa5ee518024b0fb172e8",
};

export const stripeConfigPrivate = { ...stripeConfig, ...{} };

export const rrfConfig = {};

export const webexConfig = {
  redirectUrl: "https://test.blendedcourse.com/account/integrations",
};

export const youtubeConfig = {
  key: "AIzaSyBWnPbdfKLeQu26krqKfeVWHskP-M8wn_M",
};

export const googleBooksConfig = {
  key: "AIzaSyD3o0U-msv0Zwr9_XUjtNmspbWbAeaA26g",
};

export const customSearchConfig = {
  key: "AIzaSyAPiatS7uVrcQx2F-MNZPGeBiPKUxK_B4E",
};

export const webexConfigPrivate = { ...webexConfig, ...{} };

export const firebaseConfig = {
  apiKey: "AIzaSyD98LV5DDTGmqSTjA2hRzqkmHGDCxIPYjA",
  authDomain: "blended-test-branch.firebaseapp.com",
  projectId: "blended-test-branch",
  storageBucket: "blended-test-branch.appspot.com",
  messagingSenderId: "1029416347813",
  appId: "1:1029416347813:web:df2714b45befc60615854c",
  measurementId: "G-W0FLF2PFEK",
  serviceAccountId:
    "firebase-adminsdk-iqfnl@blended-test-branch.iam.gserviceaccount.com",
};

export const appConfig = {
  authorizeDocUrl: "https://test.blendedcourse.com/content/",
  authorisationDone: "https://test.blendedcourse.com/contentAuthorizationDone",
  canvasLtiUrl: "https://test.blendedcourse.com/lti",
  presentDocUrl: "https://test.blendedcourse.com/doc/",
  completeUrl: "https://test.blendedcourse.com/",
};

export const reflectConfig = {
  avoidRecaptchaUsingReflect: true,
};

export const courseMapConfig = {
  clientId: "e9d47668ffc8c3a694bd43d3d95bf03d",
  clientSecret: "93bd95bb67c00f5f95170a09fc61b9b0",
  accessToken: "4356d74f3a5b0324db74a9f075af0e26",
  redirectUri: "https://test.blendedcourse.com/plan/authorize",
  baseUrl: "https://plan.blendedcourse.com/version-test/api/1.1/obj",
  authUrl: "https://plan.blendedcourse.com/version-test/api/1.1",
  courseUrl:
    "https://plan.blendedcourse.com/version-test/course-editor?courseId=",
  projectUrl:
    "https://plan.blendedcourse.com/version-test/course-dash?tab=course&courseId=",
  dashUrl: "https://plan.blendedcourse.com/version-test/course-dash?courseId=",
  homeUrl: "https://plan.blendedcourse.com/version-test",
};

export const ltiConfig = {
  url: "https://test.blendedcourse.com/lti",
};

export const functionsConfig = {
  url: "https://us-central1-blended-test-branch.cloudfunctions.net/",
};
